import styled from 'styled-components';
import { SectionWrapper } from '@/components';
import { ThumbnailTitle } from '../Thumbnail/Thumbnail.styles';

export const ThumbnailCarouselWrapper = styled(SectionWrapper)`
  padding-right: 0;
  
  ${ThumbnailTitle} {
    -webkit-line-clamp: 2;
  }
`;
