import { SectionTitle, Carousel } from '@/components';
import { type IThumbnailCarousel, ResponsiveCollection } from '@/interfaces';
import { replacePath } from '@/utils';
import { ACCEPTED_ARTICLE_TYPES, RouteName } from '@/constants';
import { ThumbnailCarouselWrapper } from './ThumbnailCarousel.styles';
import { useResponsive } from '@/hooks';

const ThumbnailCarousel: React.FC<IThumbnailCarousel> = ({ tag, thumbnails }) => {
  const { title, type, slug, hide } = tag;
  if (!thumbnails?.length) return null;

  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  const expandLink = slug
    ? replacePath(RouteName.Article, [type && ACCEPTED_ARTICLE_TYPES[type], slug])
    : `/${type && ACCEPTED_ARTICLE_TYPES[type]}`;

  return (
    <ThumbnailCarouselWrapper>
      {title && <SectionTitle>{title}</SectionTitle>}
      <Carousel carouselData={thumbnails} expandLink={expandLink} hideViewAll={hide} slidesToShow={isMobile ? 2 : 3} />
    </ThumbnailCarouselWrapper>
  );
};

export default ThumbnailCarousel;
